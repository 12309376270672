import { getDispatch } from '../get-dispatch';
import { DispatchAction } from '../utility/dispatch-action';
import {
  OtherServiceActions,
  OTHER_SERVICE_STATE_NAMESPACE
} from './other-service.module-types';

export const dispatchOtherServiceAction: DispatchAction<OtherServiceActions> = getDispatch(
  OTHER_SERVICE_STATE_NAMESPACE
);
