import { Vue, Component, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import {
  EditOtherServicePriceInput,
  OtherServicePriceViewModel,
  OtherServiceViewModel
} from '@/api/api';
import OtherServicePriceFormModal from '@/components/otherServices/OtherServicePriceFormModal.vue';
import OtherServicePriceFormModalClass from '@/components/otherServices/OtherServicePriceFormModal';
import ApiClientFactory from '@/api/apiClientFactory';
import {
  OtherServiceAction,
  OtherServiceGetter,
  OtherServiceNamespace
} from '@/store/otherService/other-service.module-types';
import { dispatchOtherServiceAction } from '@/store/otherService/other-service.dispatch';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    OtherServicePriceFormModal
  }
})
export default class OtherServicePrice extends Vue {
  @OtherServiceNamespace.Getter(OtherServiceGetter.otherService)
  otherService!: OtherServiceViewModel;

  editOtherServiceInputs: EditOtherServicePriceInput[] = [];
  otherServiceId = '';
  $refs!: {
    otherServicePriceFormModal: InstanceType<
      typeof OtherServicePriceFormModalClass
    >;
  };

  @Watch('otherServiceId')
  getOtherServceById(): void {
    if (this.otherServiceId) {
      dispatchOtherServiceAction(
        OtherServiceAction.loadOtherService,
        parseInt(this.otherServiceId)
      );
    } else {
      dispatchOtherServiceAction(OtherServiceAction.clearOtherService);
    }
  }

  mounted() {
    this.otherServiceId = this.$route.params.id;
  }

  addQuantity() {
    this.$refs.otherServicePriceFormModal.openCreateModal();
  }

  @Watch('otherService')
  otherServiceViewModelUpdated() {
    this.editOtherServiceInputsUpdated(
      this.otherService.otherServicePriceViewModels
    );
  }

  editOtherServiceInputsUpdated(
    otherServicePriceViewModels: OtherServicePriceViewModel[] | undefined
  ) {
    if (otherServicePriceViewModels) {
      this.editOtherServiceInputs = otherServicePriceViewModels?.map(
        (item) =>
          new EditOtherServicePriceInput({
            quantity: item.quantity,
            price: item.price
          })
      ) as EditOtherServicePriceInput[];
    } else {
      this.editOtherServiceInputs = [];
    }
  }

  otherServicePriceFormModalOnSubmit(quantity: string, index?: number) {
    const isExisting = this.editOtherServiceInputs.some(
      (item, i) => item.quantity === parseInt(quantity) && i !== index
    );

    if (isExisting) {
      // show toast
      this.$bvToast.toast('Số lượng đã tồn tại.', {
        title: 'Số lượng',
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }

    if (index) {
      this.editOtherServiceInputs[index].quantity = parseInt(quantity);
    } else {
      this.editOtherServiceInputs.push(
        new EditOtherServicePriceInput({
          quantity: parseInt(quantity),
          price: 0
        })
      );
    }

    this.orderQuanitty();

    // hide modal
    this.$refs.otherServicePriceFormModal.$refs.modal.hide();
  }

  orderQuanitty() {
    this.editOtherServiceInputs = this.editOtherServiceInputs.sort(
      (a, b) => a.quantity - b.quantity
    );
  }

  editQuantity(index: number) {
    this.$refs.otherServicePriceFormModal.openEditModal(
      this.editOtherServiceInputs[index].quantity,
      index
    );
  }

  removeQuantity(index: number) {
    this.editOtherServiceInputs.splice(index, 1);
  }

  submit() {
    const client = new ApiClientFactory().otherServiceClient();

    client
      .setPrice(parseInt(this.otherServiceId), this.editOtherServiceInputs)
      .then(() => {
        // show toast
        this.$bvToast.toast('Chỉnh sửa thành công', {
          title: 'Set giá bán - chi phí vốn',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }
}
